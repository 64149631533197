<template>
  <div id="app">
    <div class="navbar">
      <div class="navtitle">
        <img src="./assets/home/logo_1.png" alt="">
        <div class="title">
          <div>中国人民政协商会议昭通市委员会</div>
          <div>At the Zhaotong City Committee of the chamber of Commerce of the Chinese people's political commissar</div>
        </div>
      </div>
      <div class="tabs">
        <div class="tabslist" v-for="(item,index) in navTabs" :key="index" @click="chooseNavbar(item)">
          <span class="tabsname"> {{item.name}}</span>
        </div>
      </div>

    </div>

    <transition name="fade">
      <router-view :key="$route.fullPath" />
    </transition>

  </div>
</template>

<script>
import { getNavlist } from "@/api/index";
export default {
  data() {
    return {
      navTabs: [],
      chooseStyle: {
        color: " #BB0C0C",
        background: "#fff",
        borderBottom: "13px solid #BB0C0C",
      },
    };
  },
  mounted() {
    this.navList();
  },
  methods: {
    //导航栏
    async navList() {
      await getNavlist().then((res) => {
        this.navTabs = res.list;
        let shouye = { a: "", c: "", id: 0, name: "首页" };
        let shuzi = { a: "", c: "", id: 0, name: "数字政协" };
        this.navTabs.unshift(shouye);
        this.navTabs.push(shuzi);
      });
    },

    chooseNavbar(e) {
      switch (e.name) {
        case "首页":
          this.$router.push("/");
          break;
        case "数字政协":
          window.open(`http://ydlz.ynzx.gov.cn/ynzx/login.jsp`);
          break;
        case "视听政协":
          this.$router.push(`video?navname=${e.name}&c=${e.c}&a=${e.a}`);
          break;
        default:
          this.$router.push(`news?navname=${e.name}&c=${e.c}&a=${e.a}`);
          break;
      }
    },
  },
};
</script>

<style lang="scss" >
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.navbar {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 230px;
  background-image: url(./assets/home/bg.png);
  background-repeat: no-repeat;
  background-size: cover;

  .navtitle {
    height: 150px;
    border-bottom: 2px solid #fff3c3;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    .title {
      color: #e03021;
      margin-left: 33px;

      :nth-child(1) {
        font-size: 44px;
        font-weight: 600;
      }

      :nth-child(2) {
        font-size: 14px;
      }
    }

    img {
      width: 126px;
      height: 122px;
    }
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    font-size: 0.18rem;
    font-weight: 600;
    color: #454545;
    height: 79px;
    .tabsname {
      padding-left: 0.2rem;
      padding-right: 0.24rem;
      display: block;
      border-right: 1px solid #fdac6e;
    }
    .tabslist {
      cursor: pointer;
      padding-top: 22px;
    }

    .tabslist:hover {
      height: 79px;
      color: #bb0c0c;
      background: #fff;
      border-bottom: 13px solid #bb0c0c;
    }
  }
}

body::-webkit-scrollbar {
  width: 0;
}
#app {
  height: 100%;
  font-family: SourceHanSansCN;
}
.fade-enter,
.fade-leave-to {
  visibility: hidden;
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-to,
.fade-leave {
  visibility: visible;
  opacity: 1;
}
</style>
