import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Print from 'vue-print-nb'

import 'swiper/css/swiper.min.css';
import 'swiper/css/swiper.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'


Vue.config.productionTip = false
Vue.use(ViewUI)
Vue.use(Print)


new Vue({
  router,

  render: h => h(App),
}).$mount('#app')

function setRem() {
  var whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}
window.addEventListener('load', setRem);
window.addEventListener('resize', setRem)